import React, {useState} from "react"
import { graphql } from 'gatsby';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss";
import "../scss/grunticon.scss"; 
import "../scss/custom.scss";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer"; 
import VideoDetail from "../components/Home/Videos/VideoDetail";

const VideoTemplate = (props) => {
    const GQLPage = props.data.glstrapi?.video;
    const [showMenu, setShowMenu] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
  

    const handlerClick = () => {
        var root = document.getElementsByTagName('html')[0];
        setShowMenu(!showMenu)
        if (showMenu == true) {
            document.body.className = '';
            root.className = '';
        } else {
            document.body.classList.add("overflow-hidden");
            root.classList.add("overflow-hidden");
        }
    }

    const handlerSearchClick = () => {
        setShowSearch(!showSearch)
        setShowMenu(false)
        if (showSearch == true) {
            document.body.className = '';

        } else {
            document.body.classList.add("overflow-hidden");
        }
    }
    
    return (
        <div className={` ${(showMenu || showSearch) ? "wrapper video-page open-search-block" : "wrapper video-page"}`} > 
             <Header showMenu={showMenu} handlerClick={handlerClick} handlerSearchClick={handlerSearchClick} showSearch={showSearch} fixed={true} /> 
            <VideoDetail video={GQLPage}  />
            <Footer popularSearch={'Popular_Search_Home'} popularSearchType="static" />
        </div>
    )
}

export default VideoTemplate;

export const pageQuery = graphql`
    query VideoQuery($id: ID!) {
        glstrapi {
            video(id: $id, publicationState: LIVE) {
                id
                Title
                Meta_Title
                Meta_Description
                URL
                Publish
                Embed_Video_URL
                Image {
                    url
                    alternativeText
                    formats
                }
                AutoPlay
                Repeat
                createdAt 
                video_categories {
                    URL
                }
            }
        }
    }
`